import { Box, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import NavbarDesktop from './navbar.desktop.component';
import NavbarMobile from './navbar.mobile.component';
import logo from 'assets/scmlkastellaun_logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import FunctionIconsList from './functionIconsList.component';
import header from 'assets/header_background.jpg';

function Navbar() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: `url(${header})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          padding: '1%',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            width: '100%',
            maxWidth: 'xl',
            margin: '0 auto',
          }}>
          {/* Logo */}
          <Link to="/">
            <Box
              component="img"
              sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }}
              src={logo}
              alt="logo"
            />
          </Link>
          {/* This will be displayed on larger screens */}
          <NavbarDesktop />
          {/* Control icons for small screens */}
          <Box sx={{ display: { md: 'flex', lg: 'none' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <FunctionIconsList />
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setIsMobileNavOpen(isMobileNavOpen ? false : true)}
                color="inherit"
                sx={{ mb: 0.8 }}>
                <MenuIcon sx={{ fontSize: '35px' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* This will be displayed on small screens */}
      {isMobileNavOpen && (
        <Box
          sx={{
            position: 'relative',
            top: '100%',
            width: '100%',
            bgcolor: '#2c2f2b',
            color: 'white',
            zIndex: 1,
          }}>
          <NavbarMobile setIsMobileNavOpen={setIsMobileNavOpen} />
        </Box>
      )}
    </>
  );
}

export default Navbar;
