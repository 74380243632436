import { PictureAsPdf } from '@mui/icons-material';
import { fileUpload } from 'functions/fileUpload.functions';
import { MenuButton } from 'mui-tiptap';
import { useRef } from 'react';

function PdfUploadButton({ rteRef }) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  function triggerFileInput() {
    fileInputRef.current?.click();
  }

  function handlePdfUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log('PDF Upload');
    if (event.target.files) {
      const files = Array.from(event.target.files) as File[];
      files.map((file) => {
        fileUpload(file).then((result) => {
          const fileUrl = result.src;
          rteRef.current?.editor?.commands.insertContent({
            type: 'RtePdfComponent',
            attrs: { file: fileUrl },
          });
        });
      });
    }
  }

  return (
    <>
      <input
        type="file"
        accept="application/pdf"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handlePdfUpload}
      />
      <MenuButton tooltipLabel="PDF Hochladen" onClick={triggerFileInput}>
        <PictureAsPdf />
      </MenuButton>
    </>
  );
}

export default PdfUploadButton;
