import { createTheme } from '@mui/material';
import { createContext, useState } from 'react';

export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
    },
  },
});

export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#1e2122',
      paper: '#181a1b',
    },
  },
});

interface ThemeContextProps {
  colorMode: string;
  setColorMode: (mode: string) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  colorMode: 'light',
  setColorMode: () => {},
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
}): React.ReactElement | null => {
  const [colorMode, setColorMode] = useState('light');

  return (
    <ThemeContext.Provider value={{ colorMode, setColorMode }}>{children}</ThemeContext.Provider>
  );
};
