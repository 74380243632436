import { createContext, useState } from 'react';

interface DialogProps {
  message?: string;
  content?: string;
  thirdOptionText?: string;
  onThirdOption?: () => void;
  onConfirm: () => void;
  open: boolean;
}

interface DialogContextProps {
  dialogProps: DialogProps;
  setDialogProps: (props: DialogProps) => void;
}

export const DialogContext = createContext<DialogContextProps>({
  dialogProps: {
    message: 'Bestätigen?',
    content: '',
    thirdOptionText: '',
    onThirdOption: () => {},
    onConfirm: () => {},
    open: false,
  },
  setDialogProps: () => {},
});

interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({
  children,
}): React.ReactElement | null => {
  const [dialogProps, setDialogProps] = useState({
    message: 'Bestätigen?',
    content: '',
    thirdOptionText: '',
    onThirdOption: () => {},
    onConfirm: () => {},
    open: false,
  });

  const updateDialogProps = (props: DialogProps) => {
    setDialogProps((prev) => ({
      ...prev,
      content: props.content || '',
      thirdOptionText: props.thirdOptionText || '',
      onThirdOption: props.onThirdOption || (() => {}),
      ...props,
    }));
  };

  return (
    <DialogContext.Provider
      value={{
        dialogProps,
        setDialogProps: updateDialogProps,
      }}>
      {children}
    </DialogContext.Provider>
  );
};
