import ContentData from 'dtos/contentData';

export async function getContent(route: string): Promise<ContentData[]> {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/content?' +
      new URLSearchParams({
        route: route,
      }).toString(),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error while fetching content', error);
    });
}

export async function deleteContent(contentId: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/content/delete?' +
      new URLSearchParams({
        id: contentId,
      }).toString(),
    {
      method: 'DELETE',
      credentials: 'include',
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    console.log('Deleted content with id: ', contentId);
    return response.json();
  });
}

export async function createContent(pageContent: ContentData): Promise<ContentData> {
  return fetch(process.env.REACT_APP_API_URL + '/content/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(pageContent),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error while creating content', error);
    });
}

export async function updateContent(id: string, pageContent: ContentData): Promise<ContentData> {
  return fetch(
    process.env.REACT_APP_API_URL + '/content/update?' + new URLSearchParams({ id: id }).toString(),
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(pageContent),
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    console.log('Updated post with ID: ', id);
    return response.json();
  });
}
