import { DatePicker, DatePickerProps, LocalizationProvider, deDE } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

function CustomDatePicker(props: DatePickerProps<dayjs.Dayjs>) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale="de">
      <DatePicker label="Datum" format="DD.MM.YYYY" sx={{ width: 'fit-content' }} {...props} />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
