import { LightMode, DarkMode, Logout } from '@mui/icons-material';
import { Box } from '@mui/material';
import { socialIconSxProps } from 'config/socialIconSxProps';
import { AuthContext } from 'contexts/auth.context';
import { ThemeContext } from 'contexts/theme.context';
import { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

function FunctionIconsList() {
  const { user, userLogout } = useContext(AuthContext);
  const { colorMode, setColorMode } = useContext(ThemeContext);

  useEffect(() => {
    const storedColorMode = Cookies.get('colorMode') || 'light';
    setColorMode(storedColorMode);
  }, [setColorMode]);

  function handleColorModeToggle() {
    const newColorMode = colorMode === 'dark' ? 'light' : 'dark';
    setColorMode(newColorMode);
    Cookies.set('colorMode', newColorMode, { expires: 365 });
  }

  return (
    <>
      <Box>
        {colorMode === 'dark' ? (
          <LightMode
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
            onClick={handleColorModeToggle}
          />
        ) : (
          <DarkMode
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
            onClick={handleColorModeToggle}
          />
        )}
      </Box>
      {user?.is_admin && (
        <Box>
          <Logout
            className="logoutIcon"
            onClick={userLogout}
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
          />
        </Box>
      )}
    </>
  );
}

export default FunctionIconsList;
