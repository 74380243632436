import { Box } from '@mui/material';
import ManagePostForm from 'components/news/managePostForm.component';
import TitleComponent from 'components/text/title.component';
import { useParams } from 'react-router-dom';

function ManagePostsPage() {
  const { id } = useParams<{ id?: string }>();
  return (
    <Box>
      <TitleComponent text={id ? 'Beitrag Aktualisieren' : 'Beitrag Erstellen'} />
      <ManagePostForm postId={id} />
    </Box>
  );
}

export default ManagePostsPage;
