export const newsTags = [
  'Allgemein',
  'Turniere',
  'Jugend',
  'B-Klasse',
  'A-Klasse',
  'Bezirksliga',
  'Rheinlandliga',
  '2. RLP-Liga Nord',
  '1. RLP-Liga Nord',
  'Erste Mannschaft',
  'Zweite Mannschaft',
  'Dritte Mannschaft',
  'Vierte Mannschaft',
  'Fünfte Mannschaft',
  'Sechste Mannschaft',
];
