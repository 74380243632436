import { newsTags } from 'config/newsTags';
import CustomButton from './button.component';
import { Box, BoxProps } from '@mui/material';
import { green, grey } from '@mui/material/colors';

interface TagSelectionProps extends BoxProps {
  tags: string[];
  setTags: (tags: string[]) => void;
}

function TagSelection({ tags = [], setTags, ...boxProps }: TagSelectionProps) {
  return (
    <Box {...boxProps}>
      {newsTags.map((tagName) => (
        <CustomButton
          key={tagName}
          onClick={() =>
            setTags(tags.includes(tagName) ? tags.filter((t) => t !== tagName) : [...tags, tagName])
          }
          sx={{
            mr: 1,
            mb: 1,
            bgcolor: tags.includes(tagName) ? green[700] : grey[700],
            '&:hover': {
              bgcolor: tags.includes(tagName) ? green[800] : grey[800],
            },
          }}>
          {tagName}
        </CustomButton>
      ))}
    </Box>
  );
}

export default TagSelection;

// import {
//   Box,
//   BoxProps,
//   Chip,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   OutlinedInput,
//   Select,
//   SelectChangeEvent,
// } from '@mui/material';
// import { newsTags } from 'config/newsTags';

// interface TagSelectionProps extends BoxProps {
//   tags: string[];
//   setTags: (tags: string[]) => void;
// }

// function TagSelection({ tags = [], setTags, ...boxProps }: TagSelectionProps) {
//   function handleChange(event: SelectChangeEvent<typeof tags>) {
//     setTags(event.target.value as string[]);
//   }

//   return (
//     <Box {...boxProps}>
//       <FormControl sx={{ m: 1, width: 300 }}>
//         <InputLabel id="demo-multiple-chip-label">Filter</InputLabel>
//         <Select
//           labelId="demo-multiple-chip-label"
//           id="demo-multiple-chip"
//           multiple
//           value={tags}
//           onChange={handleChange}
//           input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value} label={value} />
//               ))}
//             </Box>
//           )}>
//           {newsTags.map((name) => (
//             <MenuItem key={name} value={name}>
//               {name}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// }

// export default TagSelection;
