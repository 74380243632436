import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface TitleComponentProps extends TypographyProps {
  text: string;
}

const TitleComponent: React.FC<TitleComponentProps> = ({
  text,
  variant = 'h4',
  mt = '4vh',
  mb = '4vh',
  ...props
}) => (
  <Typography {...props} variant={variant} mt={mt} mb={mb}>
    {text}
  </Typography>
);

export default TitleComponent;
