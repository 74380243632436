import { Box } from '@mui/material';
import ContentComponent from 'components/input/content.component';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface BasePageProps {
  title?: string;
}

function BasePage({ title }: BasePageProps) {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - ' + (title ?? location.pathname.replace('/', ''));
  });

  return (
    <Box>
      <ContentComponent
        route={location.pathname.replace('/', '')}
        title={title ? title : undefined}
      />
    </Box>
  );
}

export default BasePage;
