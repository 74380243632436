import { NodeViewWrapper, ReactNodeViewRenderer, type NodeViewProps } from '@tiptap/react';
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import TipTapImage from '@tiptap/extension-image';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { TbFloatLeft, TbFloatNone, TbFloatRight } from 'react-icons/tb';
import { IoMdResize } from 'react-icons/io';
import { ThemeContext } from 'contexts/theme.context';
import CustomTextField from 'components/input/textfield.component';
import { grey } from '@mui/material/colors';

const BORDER_COLOR = '#0096fd';
const LARGE_SIZE = 1000;
const MEDIUM_SIZE = 480;
const SMALL_SIZE = 200;
const MENU_ICON_SIZE = 20;

const InlineImageTemplate = ({ node, editor, updateAttributes }: NodeViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [editing, setEditing] = useState(false);
  const [resizingStyle, setResizingStyle] = useState<Pick<CSSProperties, 'width'> | undefined>();
  const [subtitleText, setSubtitleText] = useState(node.attrs.subtitle || '');
  const { float, ...restAttrs } = node.attrs;
  const { colorMode } = useContext(ThemeContext);

  const imageMenuItemProps = {
    sx: {
      cursor: 'pointer',
      width: MENU_ICON_SIZE * 1.5,
      height: MENU_ICON_SIZE * 1.5,
      bgcolor: 'background.default',
      '&:hover': {
        bgcolor: colorMode === 'dark' ? '#303233' : '#d0d0e0',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mx: 0.2,
    },
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setEditing(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [editing]);

  function handleResize(newWidth: number) {
    if (!imgRef.current) return;
    setResizingStyle({ width: newWidth });
    updateAttributes({ width: newWidth });
  }

  function handleFloatChange(newFloat: 'left' | 'right' | 'none') {
    updateAttributes({ float: newFloat });
  }

  function handleSubtitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSubtitle = event.target.value;
    setSubtitleText(newSubtitle);
    updateAttributes({ subtitle: newSubtitle });
  }

  return (
    <NodeViewWrapper
      ref={containerRef}
      as="div"
      draggable
      data-drag-handle
      onClick={() => setEditing(true)}
      onBlur={() => setEditing(false)}
      style={{
        position: 'relative',
        display: 'inline-block',
        float: float || 'none',
        margin: float ? '0 5px 5px 0' : '0',
      }}>
      {editing && editor.isEditable && (
        <>
          <Box
            sx={{
              bgcolor: 'background.paper',
              border: '1px solid',
              borderColor: '#9e9e9e',
              borderRadius: '4px',
              display: 'flex',
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 100,
            }}>
            <Box
              sx={{
                position: 'absolute',
                bottom: '-10px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid #9e9e9e',
              }}
            />
            <Box sx={{ display: 'flex', margin: 0.5 }}>
              {/* Size controls */}
              <Tooltip title="Größe auf Klein ändern">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.width == SMALL_SIZE
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleResize(SMALL_SIZE)}>
                  <IoMdResize fontSize={MENU_ICON_SIZE / 1.4} />
                </Box>
              </Tooltip>
              <Tooltip title="Größe auf Mittel ändern">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.width == MEDIUM_SIZE
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleResize(MEDIUM_SIZE)}>
                  <IoMdResize fontSize={MENU_ICON_SIZE / 1.2} />
                </Box>
              </Tooltip>
              <Tooltip title="Größe auf Groß ändern">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.width == LARGE_SIZE
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleResize(LARGE_SIZE)}>
                  <IoMdResize fontSize={MENU_ICON_SIZE} />
                </Box>
              </Tooltip>
              <Divider orientation="vertical" sx={{ mx: '1%', bgcolor: '#bdbdbd', width: '1px' }} />
              {/* Float controls */}
              <Tooltip title="Bild links ausrichten und mit Text umschließen">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.float == 'left'
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleFloatChange('left')}>
                  <TbFloatLeft fontSize={MENU_ICON_SIZE} />
                </Box>
              </Tooltip>
              <Tooltip title="Entfernen der Ausrichtung und des umschließenden Textes">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.float == 'none'
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleFloatChange('none')}>
                  <TbFloatNone fontSize={MENU_ICON_SIZE} />
                </Box>
              </Tooltip>
              <Tooltip title="Bild rechts ausrichten und mit Text umschließen">
                <Box
                  sx={{
                    ...imageMenuItemProps.sx,
                    bgcolor:
                      node.attrs.float == 'right'
                        ? colorMode == 'dark'
                          ? grey[800]
                          : grey[400]
                        : 'background.default',
                  }}
                  onClick={() => handleFloatChange('right')}>
                  <TbFloatRight fontSize={MENU_ICON_SIZE} />
                </Box>
              </Tooltip>
            </Box>
          </Box>
          {/* Handle image borders */}
          {[
            { left: 0, top: 0, height: '100%', width: '1px' },
            { right: 0, top: 0, height: '100%', width: '1px' },
            { top: 0, left: 0, width: '100%', height: '1px' },
            { bottom: 0, left: 0, width: '100%', height: '1px' },
          ].map((style, i) => (
            <div
              key={i}
              style={{ position: 'absolute', backgroundColor: BORDER_COLOR, ...style }}></div>
          ))}
        </>
      )}
      <img
        {...restAttrs}
        ref={imgRef}
        style={{
          ...resizingStyle,
          cursor: 'default',
          margin: 5,
          marginBottom: float === 'none' ? 0 : 5,
          float: float || 'none',
        }}
      />
      {((editing && editor.isEditable) || subtitleText) && (
        <Box
          sx={{
            textAlign: 'center',
          }}>
          {editing && editor.isEditable ? (
            <CustomTextField
              label="Bildbeschreibung"
              multiline
              value={subtitleText}
              onChange={handleSubtitleChange}
              fullWidth
              sx={{
                maxWidth: node.attrs.width,
                mb: 1,
                mt: float === 'none' ? 0 : 1,
                paddingX: '2%',
              }}
              size="small"
            />
          ) : (
            <Typography color={grey[700]} sx={{ fontStyle: 'italic', maxWidth: node.attrs.width }}>
              {subtitleText}
            </Typography>
          )}
        </Box>
      )}
    </NodeViewWrapper>
  );
};

const InlineImageExtension = TipTapImage.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: { renderHTML: ({ width }) => ({ width }) },
      height: { renderHTML: ({ height }) => ({ height }) },
      float: {
        default: 'none',
        renderHTML: ({ float }) => ({
          style: `float: ${float || 'none'};`,
        }),
        parseHTML: (element) => element.style.float || 'none',
      },
      subtitle: {
        default: '',
        renderHTML: ({ subtitle }) => ({
          subtitle: subtitle,
        }),
        parseHTML: (element) => element.getAttribute('subtitle') || '',
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(InlineImageTemplate);
  },
}).configure({ inline: true });

export default InlineImageExtension;
