import { Box, Button } from '@mui/material';
import TitleComponent from 'components/text/title.component';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}>
      <TitleComponent text="Schachmatt!" mb={'2vh'} />
      <TitleComponent text="Hier geht es nicht weiter" mt={0} mb={'2vh'} />
      <Button
        sx={{
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
          color: 'black',
        }}
        onClick={() => navigate('/')}>
        Zurück zur Homepage
      </Button>
    </Box>
  );
}

export default NotFoundPage;
