import { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.mjs';
import CustomIconButton from './input/iconButton.component';
import { IoIosArrowBack, IoIosArrowForward, IoMdDownload } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { saveAs } from 'file-saver';

function PDFViewer({ file }) {
  const [pageImages, setPageImages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    renderPDFToImages();
  }, [file]);

  async function renderPDFToImages() {
    try {
      const loadingTask = pdfjsLib.getDocument(file);
      const pdf = await loadingTask.promise;
      const images: string[] = [];

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          throw new Error('Failed to get canvas context');
        }
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        images.push(canvas.toDataURL());
      }

      setPageImages(images);
      setCurrentPage(0);
    } catch (err) {
      console.error('Error rendering PDF:', err);
    }
  }

  const handleDownloadClick = async () => {
    try {
      const response = await fetch(file, { mode: 'cors' });
      const blob = await response.blob();
      saveAs(blob, 'pdf-document.pdf');
    } catch (err) {
      console.error('Error downloading PDF:', err);
    }
  };

  return (
    <Box sx={{ maxWidth: '100%', maxHeight: '100%' }}>
      {pageImages.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'white',
              bgcolor: '#2c2f2b',
              position: 'relative',
            }}>
            <Box />
            <Box
              sx={{
                position: { sm: 'absolute' },
                left: { sm: '50%' },
                transform: { sm: 'translateX(-50%)' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CustomIconButton
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={currentPage === 0}>
                <IoIosArrowBack />
              </CustomIconButton>
              <Typography sx={{ textAlign: 'center' }}>
                {`Seite ${currentPage + 1}/${pageImages.length}`}
              </Typography>
              <CustomIconButton
                onClick={() =>
                  setCurrentPage((prevPage) => Math.min(prevPage + 1, pageImages.length - 1))
                }
                disabled={currentPage === pageImages.length - 1}>
                <IoIosArrowForward />
              </CustomIconButton>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <CustomIconButton onClick={handleDownloadClick}>
                <IoMdDownload />
              </CustomIconButton>
              <CustomIconButton onClick={() => window.open(file)}>
                <FiExternalLink />
              </CustomIconButton>
            </Box>
          </Box>
          <Divider />
          <Box component="img" src={pageImages[currentPage]} alt={`Page ${currentPage + 1}`} />
        </Box>
      ) : (
        <Box>
          <Typography>
            Falls die PDF-Datei nicht angezeigt wird, kann sie&nbsp;
            <Link to={file} target="_blank">
              hier
            </Link>
            &nbsp;geöffnet werden.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default PDFViewer;
