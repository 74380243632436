import { UserDto } from 'dtos/user.dto';

export async function login(email: string, password: string): Promise<boolean> {
  console.log('login', email);
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
}

export async function logout(): Promise<boolean> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
    method: 'POST',
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  console.log('Logged out');
  return true;
}

export async function authenticate(): Promise<UserDto | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const user = await response.json();
    console.log('Authenticated', user.email);
    return user;
  } catch (error) {
    console.error('Error while authenticating', error);
    return null;
  }
}
