import { grey } from '@mui/material/colors';

export const socialIconSxProps = {
  color: 'white',
  fontSize: '24px',
  mx: '8px',
  borderRadius: '20%',
  '&:hover': {
    color: grey[800],
    bgcolor: 'white',
    boxShadow: '0 0 0 5px white',
  },
};
