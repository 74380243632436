import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import RtePdfComponent from './pdf.rte-component';

const PdfExtension = Node.create({
  name: 'RtePdfComponent',
  inline: false,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      file: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'RtePdfComponent' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['RtePdfComponent', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RtePdfComponent);
  },
});

export default PdfExtension;
