import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomIconButton from 'components/input/iconButton.component';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoMdClose, IoMdDownload } from 'react-icons/io';
import { FiExternalLink } from 'react-icons/fi';
import { saveAs } from 'file-saver';

interface ImageProps {
  src: string;
  subtitle: string;
}

interface ImageCarousellProps {
  images: ImageProps[];
  startIndex: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function ImageCarousellComponent({
  images,
  startIndex = 0,
  open = false,
  setOpen,
}: ImageCarousellProps) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getMaxPreviewImages = () => {
    if (isXs) return 0;
    if (isSm) return 3;
    if (isMd) return 5;
    if (isLg) return 5;
    return 3;
  };

  const [index, setIndex] = useState(startIndex);
  const [previewStartIndex, setPreviewStartIndex] = useState(0);
  const [maxPreviewImages, setMaxPreviewImages] = useState(getMaxPreviewImages());

  useEffect(() => {
    setMaxPreviewImages(getMaxPreviewImages());
  }, [isXs, isSm, isMd, isLg]);

  useEffect(() => {
    setIndex(startIndex);
    setPreviewStartIndex(Math.max(0, startIndex - Math.floor(maxPreviewImages / 2)));
  }, [startIndex, maxPreviewImages]);

  useEffect(() => {
    setPreviewStartIndex(Math.max(0, index - Math.floor(maxPreviewImages / 2)));
  }, [index, maxPreviewImages]);

  const handleBackgroundClick = (event: React.MouseEvent) => {
    if (event.target instanceof HTMLDivElement && event.target.id === 'dialog-background') {
      setOpen(false);
      setIndex(0);
    }
  };

  const handleDownloadClick = async () => {
    try {
      const imageUrl = images[index].src;
      const response = await fetch(images[index].src, { mode: 'cors' });
      const blob = await response.blob();
      saveAs(blob, `image-${index + 1}.${imageUrl.split('.').pop()}`);
    } catch (err) {
      console.error('Error downloading PDF:', err);
    }
  };

  const previewImages = images.slice(previewStartIndex, previewStartIndex + maxPreviewImages);
  const prevImage = previewStartIndex > 0 ? images[previewStartIndex - 1] : null;
  const nextImage =
    previewStartIndex + maxPreviewImages < images.length
      ? images[previewStartIndex + maxPreviewImages]
      : null;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}>
      <Box
        id="dialog-background"
        onClick={handleBackgroundClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          position: 'relative',
        }}>
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <CustomIconButton onClick={handleDownloadClick}>
            <IoMdDownload />
          </CustomIconButton>
          <CustomIconButton onClick={() => window.open(images[index].src)}>
            <FiExternalLink />
          </CustomIconButton>
          <CustomIconButton onClick={() => setOpen(false)}>
            <IoMdClose />
          </CustomIconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.3rem',
            position: 'relative',
            width: '100%',
          }}>
          <CustomIconButton
            onClick={() => setIndex((index - 1 + images.length) % images.length)}
            sx={{ position: 'absolute', left: { xs: '15px', xl: '15vw' }, zIndex: 1 }}>
            <IoIosArrowBack />
          </CustomIconButton>
          <Box
            component="img"
            src={images[index]?.src}
            sx={{
              maxWidth: '80vw',
              maxHeight: '70vh',
              objectFit: 'contain',
              margin: '0 auto',
            }}
          />
          <CustomIconButton
            onClick={() => setIndex((index + 1) % images.length)}
            sx={{ position: 'absolute', right: { xs: '15px', xl: '15vw' }, zIndex: 1 }}>
            <IoIosArrowForward />
          </CustomIconButton>
        </Box>
        <Typography color={'white'} sx={{ maxWidth: '80vw', textAlign: 'center' }}>
          {images[index]?.subtitle || '\u00A0'}
        </Typography>
        {maxPreviewImages > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.3rem',
              paddingTop: '2rem',
              position: 'relative',
              width: '100%',
            }}>
            {prevImage && (
              <Box
                component="img"
                src={prevImage.src}
                sx={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  opacity: 0.5,
                }}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.3rem' }}>
              {previewImages.map((image, i) => (
                <Box
                  key={i + previewStartIndex}
                  component="img"
                  src={image.src}
                  sx={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border:
                      i + previewStartIndex === index
                        ? '2px solid #ce9042'
                        : '2px solid transparent',
                  }}
                  onClick={() => setIndex(i + previewStartIndex)}
                />
              ))}
            </Box>
            {nextImage && (
              <Box
                component="img"
                src={nextImage.src}
                sx={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  opacity: 0.5,
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default ImageCarousellComponent;
