import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import NewsView from 'components/news/newsView.component';
import { useParams } from 'react-router-dom';
import { getPageTitle, navStructure } from 'config/navbarItems';
import BasePage from 'pages/base/base.page';

function TeamsPage() {
  const { team } = useParams<{ team?: string }>();
  const [teamName, setTeamName] = useState('');

  useEffect(() => {
    setTeamName(getPageTitle(navStructure, team || '') || team || 'Teams');
    document.title = `Schachclub ML Kastellaun - ${teamName}`;
  });

  return (
    <Box>
      <BasePage title={teamName}></BasePage>
      <NewsView
        newsTitle="Berichte"
        panelCount={4}
        presetTags={teamName ? [teamName] : undefined}
        showReadMore
      />
    </Box>
  );
}

export default TeamsPage;
