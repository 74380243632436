import { Paper } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import PDFViewer from 'components/PDFViewer.component';

function RtePdfComponent({ node }) {
  const { file } = node.attrs;

  return (
    <NodeViewWrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        mt: 1,
      }}>
      <Paper elevation={10}>
        <PDFViewer file={file} />
      </Paper>
    </NodeViewWrapper>
  );
}

export default RtePdfComponent;
