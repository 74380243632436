import { PostDto } from 'dtos/post.dto';

export interface PostFilterDto {
  tags?: string[];
  olderThen?: string;
  count?: number;
}

export async function createPost(postContent: PostDto) {
  return fetch(process.env.REACT_APP_API_URL + '/post/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(postContent),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    console.log('Created post');
    return response.json();
  });
}

export async function updatePost(id: string, content: PostDto) {
  return fetch(
    process.env.REACT_APP_API_URL + '/post/update?' + new URLSearchParams({ id: id }).toString(),
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(content),
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    console.log('Updated post with ID: ', id);
    return response.json();
  });
}

export async function getPosts(filter?: PostFilterDto): Promise<PostDto[]> {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/post?' +
      new URLSearchParams({
        ...(filter?.count && { count: filter?.count.toString() }),
        ...(filter?.tags && filter.tags.length > 0 && { tags: filter.tags.join(',') }),
        ...(filter?.olderThen && { olderThen: filter.olderThen }),
      }).toString(),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error while fetching posts', error);
    });
}

export async function getPostById(id: string): Promise<PostDto | null> {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/post?' +
      new URLSearchParams({
        id: id,
      }).toString(),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error while fetching posts', error);
    });
}

export async function deletePost(postId: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/post/delete?' +
      new URLSearchParams({
        id: postId,
      }).toString(),
    {
      method: 'DELETE',
      credentials: 'include',
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    console.log('Deleted post with ID: ', postId);
    return response.json();
  });
}
