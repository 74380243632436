import { UserDto } from 'dtos/user.dto';
import { authenticate, login, logout } from 'functions/auth.functions';
import { createContext, useEffect, useState } from 'react';

interface AuthContextProps {
  user: UserDto | undefined;
  setUser: (user: UserDto) => void;
  userLogin: (email: string, password: string) => Promise<boolean>;
  userLogout: () => Promise<boolean>;
  userAuthenticate: () => Promise<UserDto | null>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  setUser: () => {},
  userLogin: () => Promise.resolve(false),
  userLogout: () => Promise.resolve(false),
  userAuthenticate: () => Promise.resolve(null),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}): React.ReactElement | null => {
  const [user, setUser] = useState<UserDto | undefined>(undefined);

  useEffect(() => {
    authenticate().then((user) => {
      if (user) setUser(user);
    });
  }, []);

  async function userLogin(email: string, password: string): Promise<boolean> {
    await login(email, password);
    const user = await authenticate();
    if (user) {
      setUser(user);
      return true;
    }
    return false;
  }

  async function userLogout(): Promise<boolean> {
    const response = await logout();
    if (response) setUser(undefined);
    console.log(user);
    return response;
  }

  async function userAuthenticate(): Promise<UserDto | null> {
    return await authenticate();
  }

  return (
    <AuthContext.Provider value={{ user, setUser, userLogin, userLogout, userAuthenticate }}>
      {children}
    </AuthContext.Provider>
  );
};
