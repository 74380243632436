import { Box } from '@mui/material';
import DetailedNewsView from 'components/news/detailedNewsView.component';
import NewsView from 'components/news/newsView.component';
import { useParams } from 'react-router-dom';

function NewsPage() {
  const { id } = useParams<{ id?: string }>();
  return (
    <Box>
      {id ? (
        <DetailedNewsView id={id} />
      ) : (
        <NewsView panelCount={8} showReadMore showTagSelection />
      )}
    </Box>
  );
}

export default NewsPage;
