export async function fileUpload(file: File) {
  console.info('Uploading file:', file);

  const formData = new FormData();
  formData.append('file', file);

  return fetch(process.env.REACT_APP_API_URL + '/file/upload', {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<{ fileUrl: string; filename: string }>;
    })
    .then((response) => {
      console.log(response);
      return {
        src: response.fileUrl,
        filename: response.filename,
      };
    });
}

export async function imageUpload(file: File) {
  console.info('Uploading image:', file);

  const formData = new FormData();
  formData.append('file', file);

  return fetch(process.env.REACT_APP_API_URL + '/file/upload', {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<{ fileUrl: string; filename: string }>;
    })
    .then((response) => {
      console.log(response);
      return {
        src: response.fileUrl,
        alt: response.filename,
        width: 480,
      };
    });
}
