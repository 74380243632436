import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

/**
 * This extension adds a clearfix widget at the end of the document.
 * This prevents the editor from collapsing content, like images are floated.
 */
export const ClearfixExtension = Extension.create({
  name: 'clearfix',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('clearfix'),
        props: {
          decorations(state) {
            const endOfDocPosition = state.doc.nodeSize - 2;
            const decoration = Decoration.widget(endOfDocPosition, () => {
              const clearfixElement = document.createElement('div');
              clearfixElement.style.clear = 'both';
              clearfixElement.classList.add('clearfix');
              return clearfixElement;
            });

            return DecorationSet.create(state.doc, [decoration]);
          },
        },
      }),
    ];
  },
});

export default ClearfixExtension;
