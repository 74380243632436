import { Button, ButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';

function CustomButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        bgcolor: grey[700],
        '&:hover': {
          bgcolor: grey[800],
        },
        color: 'white',
        ...props.sx,
      }}
    />
  );
}

export default CustomButton;
