import { FaFileWord } from 'react-icons/fa';
import { MenuButton } from 'mui-tiptap';
import { useRef } from 'react';
import * as mammoth from 'mammoth';
import { imageUpload } from 'functions/fileUpload.functions';

function WordUploadButton({ rteRef }) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  function triggerFileInput() {
    fileInputRef.current?.click();
  }

  async function uploadBase64Image(base64: string) {
    const [header, base64Data] = base64.split(',');
    const mimeType = header.split(':')[1].split(';')[0];
    const byteCharacters = atob(base64Data);
    const byteArrays = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteArrays], { type: mimeType });
    const file = new File([blob], 'image.jpg', { type: mimeType });

    const image = await imageUpload(file);
    return image;
  }

  async function convertWordToHtml(file: File) {
    const arrayBuffer = await file.arrayBuffer();
    const { value: htmlContent } = await mammoth.convertToHtml({ arrayBuffer });

    const imageRegex = /<img[^>]+src="data:image\/([^;]+);base64,([A-Za-z0-9+/=]+)"([^>]*)(\/?)>/g;
    let updatedHtmlContent = htmlContent;
    const matches = [...htmlContent.matchAll(imageRegex)];

    for (const match of matches) {
      const [fullMatch, mimeType, base64Data] = match;
      const base64 = `data:image/${mimeType};base64,${base64Data}`;
      const image = await uploadBase64Image(base64);
      updatedHtmlContent = updatedHtmlContent.replace(
        fullMatch,
        `<img src="${image.src}" alt="${image.alt}" width="${image.width}" />`,
      );
    }
    return updatedHtmlContent;
  }

  function handleWordUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log('Word Upload');
    if (event.target.files) {
      const files = Array.from(event.target.files) as File[];
      files.map((file) => {
        convertWordToHtml(file).then((convertedHtml) => {
          rteRef.current?.editor?.commands.insertContent(convertedHtml);
        });
      });
    }
  }

  return (
    <>
      <input
        type="file"
        accept=".docx"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleWordUpload}
      />
      <MenuButton tooltipLabel="Word Hochladen" onClick={triggerFileInput}>
        <FaFileWord size={'20px'} />
      </MenuButton>
    </>
  );
}

export default WordUploadButton;
