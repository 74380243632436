import { useEffect } from 'react';
import { Box } from '@mui/material';
import NewsView from 'components/news/newsView.component';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'components/input/button.component';

function Home() {
  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - Home';
  });

  const navigate = useNavigate();

  return (
    <Box>
      <NewsView panelCount={4} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CustomButton onClick={() => navigate('/news')} sx={{ mt: '4vh' }}>
          Mehr laden
        </CustomButton>
      </Box>
    </Box>
  );
}

export default Home;
