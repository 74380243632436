import { ArrowBackIosRounded } from '@mui/icons-material';
import { MdDelete, MdFileUpload } from 'react-icons/md';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import CustomButton from 'components/input/button.component';
import CustomDatePicker from 'components/input/datepicker.component';
import CustomIconButton from 'components/input/iconButton.component';
import CustomTextField from 'components/input/textfield.component';
import RteEditorComponent from 'components/rte-editor/rte-editor.component';
import { DialogContext } from 'contexts/dialog.context';
import dayjs from 'dayjs';
import { PostDto } from 'dtos/post.dto';
import { createPost, updatePost, deletePost, getPostById } from 'functions/post.functions';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TagSelection from 'components/input/tagSelection.component';
import { grey } from '@mui/material/colors';
import { imageUpload } from 'functions/fileUpload.functions';

interface ManagePostFormProps {
  postId?: string;
}

function ManagePostForm({ postId }: ManagePostFormProps) {
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { tags } = location.state || [];

  const [post, setPost] = useState<PostDto>({
    id: '',
    title: '',
    date: new Date().toISOString(),
    summary: '',
    content: '',
    preview_image: '',
    tags: tags || [],
  });

  const [initialContent, setInitialPost] = useState<PostDto>(post);
  const [errors, setErrors] = useState({ title: '', date: '' });
  const [rteResetKey, setRteResetKey] = useState(0);

  const { setDialogProps } = useContext(DialogContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (postId) {
      getPostById(postId).then((post) => {
        if (post) {
          setPost(post);
          setInitialPost(post);
          setRteResetKey(rteResetKey ? 0 : 1);
        }
      });
    }
  }, []);

  async function handleSubmit(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: `Beitrag wirklich ${post.id ? 'Aktualisieren' : 'Erstellen'}?`,
        content: post.title,
        onConfirm: () => handleSubmit(true),
      });
    } else {
      if (post.title && post.date) {
        if (post.id) {
          await updatePost(post.id, post);
        } else {
          if (!post.preview_image) {
            const imgSrcRegex = /<img.*?src="(.*?)"/;
            const match = post.content.match(imgSrcRegex);
            if (match && match[1]) {
              post.preview_image = match[1];
            } else {
              post.preview_image = '';
            }
          }
          await createPost(post);
        }
        navigate(-1);
      } else {
        setErrors({
          title: post.title ? '' : 'Titel ist erforderlich',
          date: post.date ? '' : 'Datum ist erforderlich',
        });
      }
    }
  }

  async function handleDelete(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: 'Beitrag wirklich löschen?',
        content: post.title,
        onConfirm: () => handleDelete(true),
      });
    } else {
      await deletePost(post.id);
      navigate(-1);
    }
  }

  async function handleRevert(confirm: boolean = false) {
    if (!confirm && post !== initialContent) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen?',
        content: 'Diese Aktion kann nicht rückgängig gemacht werden!',
        onConfirm: () => handleRevert(true),
      });
    } else {
      setPost(initialContent);
      setRteResetKey(rteResetKey ? 0 : 1);
    }
  }

  async function handleCancel(confirm: boolean = false) {
    if (!confirm && post !== initialContent) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen und zurück zur Übersicht?',
        content: 'Nicht gespeicherte Änderungen gehen verloren!',
        onConfirm: () => handleCancel(true),
      });
    } else {
      navigate(-1);
    }
  }

  async function handlePreviewImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const image = await imageUpload(file);
      setPost({ ...post, preview_image: image.src });
    }
  }

  function handleRemovePreviewImage(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: 'Vorschaubild wirklich entfernen?',
        content: 'Dies kann nicht rückgängig gemacht werden!',
        onConfirm: () => handleRemovePreviewImage(true),
      });
    } else {
      setPost({ ...post, preview_image: '' });
    }
  }

  return (
    <Container>
      <Paper elevation={5}>
        <Stack spacing={2} sx={{ padding: '2%' }}>
          <CustomIconButton
            onClick={() => handleCancel()}
            sx={{
              width: 'fit-content',
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}>
            <ArrowBackIosRounded />
          </CustomIconButton>
          <CustomTextField
            label="Titel"
            value={post?.title}
            onChange={(event) => setPost({ ...post, title: event.target.value })}
            sx={{ minWidth: '20vw', maxWidth: '500px' }}
            error={!!errors.title}
            helperText={errors.title}
          />
          <CustomDatePicker
            value={dayjs(post.date)}
            onChange={(value) =>
              setPost({
                ...post,
                date: value?.toISOString() ?? new Date().toISOString(),
              })
            }
            slotProps={{
              textField: {
                error: Boolean(errors.date),
                helperText: errors.date,
              },
            }}
          />
          <Box sx={{ width: '350px', maxWidth: 'fit-content' }}>
            <Typography color="#676767" fontSize={17}>
              Vorschaubild
            </Typography>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handlePreviewImageUpload}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: post.preview_image ? 1 : 0,
              }}>
              <CustomIconButton
                onClick={() => fileInputRef.current?.click()}
                sx={{
                  width: 'fit-content',
                  bgcolor: grey[700],
                  '&:hover': {
                    bgcolor: grey[800],
                  },
                }}>
                <MdFileUpload />
              </CustomIconButton>
              {post.preview_image && (
                <CustomIconButton
                  onClick={() => handleRemovePreviewImage(false)}
                  sx={{
                    width: 'fit-content',
                    bgcolor: grey[700],
                    '&:hover': {
                      bgcolor: grey[800],
                    },
                  }}>
                  <MdDelete />
                </CustomIconButton>
              )}
            </Box>
            {post.preview_image && (
              <Box
                component="img"
                src={post.preview_image}
                alt="Post image"
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </Box>
          <Box>
            <Typography color="#676767" fontSize={17}>
              Tags
            </Typography>
            <TagSelection
              tags={post.tags}
              setTags={(tags) => {
                setPost({
                  ...post,
                  tags: tags,
                });
              }}
            />
          </Box>
          <CustomTextField
            label="Zusammenfassung"
            multiline
            value={post?.summary}
            onChange={(event) => setPost({ ...post, summary: event.target.value })}
            sx={{ minWidth: '20vw' }}
          />
          <RteEditorComponent
            content={post.content}
            placeholder="Inhalt"
            onChange={(content) => setPost({ ...post, content })}
            resetKey={rteResetKey}
          />
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: '1vw',
            }}>
            <CustomButton
              onClick={() => handleSubmit()}
              sx={{
                flexGrow: 1,
                bgcolor: 'success.main',
                '&:hover': {
                  bgcolor: 'success.dark',
                },
                color: 'black',
              }}>
              {postId ? 'Beitrag Aktualisieren' : 'Beitrag Erstellen'}
            </CustomButton>
            <CustomButton
              onClick={() => handleRevert()}
              sx={{
                flexGrow: 1,
                bgcolor: 'warning.main',
                '&:hover': {
                  bgcolor: 'warning.dark',
                },
                color: 'black',
              }}>
              Eingaben Verwerfen
            </CustomButton>
            {postId && (
              <CustomButton
                onClick={() => {
                  handleDelete();
                }}
                sx={{
                  flexGrow: 1,
                  bgcolor: 'error.main',
                  '&:hover': {
                    bgcolor: 'error.dark',
                  },
                  color: 'black',
                }}>
                Beitrag Löschen
              </CustomButton>
            )}
          </Container>
        </Stack>
      </Paper>
    </Container>
  );
}

export default ManagePostForm;
