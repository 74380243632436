import { Box } from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap';
import { imageUpload } from 'functions/fileUpload.functions';
import PdfUploadButton from './pdfUpload.rte-control';
import WordUploadButton from './wordUpload.rte-control';

export default function RteEditorControls({ rteRef }) {
  return (
    <MenuControlsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}>
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        <MenuSelectHeading />
        <MenuDivider />
        <MenuSelectFontFamily
          options={[
            { label: 'Comic Sans', value: 'Comic Sans MS, Comic Sans' },
            { label: 'Cursive', value: 'cursive' },
            { label: 'Monospace', value: 'monospace' },
            { label: 'Serif', value: 'serif' },
          ]}
        />
        <MenuDivider />
        <MenuSelectFontSize />
        <MenuDivider />
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuButtonStrikethrough />
        <MenuDivider />
        <MenuButtonSubscript />
        <MenuButtonSuperscript />
        <MenuDivider />
        <MenuButtonRemoveFormatting />
        <MenuDivider />
        <MenuButtonTextColor />
        <MenuButtonHighlightColor />
        <MenuDivider />
        <MenuSelectTextAlign />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />

        {isTouchDevice() && (
          <>
            <MenuButtonIndent />
            <MenuButtonUnindent />
          </>
        )}
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonHorizontalRule />
        <MenuDivider />
        <MenuButtonAddTable />
        <MenuButtonEditLink />
        <MenuButtonImageUpload
          onUploadFiles={async (files) => {
            const uploadedFiles = files.map(async (file) => {
              return await imageUpload(file);
            });
            return Promise.all(uploadedFiles);
          }}
        />
        <PdfUploadButton rteRef={rteRef} />
        <WordUploadButton rteRef={rteRef} />
      </Box>
    </MenuControlsContainer>
  );
}
