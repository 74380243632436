import { Avatar, Box, Button, Paper, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState, FormEvent, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';

function AdminLoginPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { userLogin } = useContext(AuthContext);

  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - Admin Login';
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    userLogin(email, password)
      .then((response) => {
        if (response) {
          navigate('/');
        } else {
          setErrorMessage('Benutzername oder Passwort falsch');
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('Benutzername oder Passwort falsch');
      });
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '3%',
      }}>
      <Paper
        elevation={3}
        sx={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '75%',
          maxWidth: '500px',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Admin Login
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {errorMessage && (
          <Typography color="error" align="center">
            {errorMessage}
          </Typography>
        )}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, maxWidth: '50%' }}>
          Login
        </Button>
      </Paper>
    </Box>
  );
}

export default AdminLoginPage;
