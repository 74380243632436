import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar.component';
import Home from './pages/home.page';
import AdminLoginPage from 'pages/admin-login/admin-login.page';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import BasePage from 'pages/base/base.page';
import { navStructure } from 'config/navbarItems';
import ManagePostsPage from 'pages/news/managePosts.page';
import NewsPage from 'pages/news/news.page';
import { useContext } from 'react';
import NoAccessPage from 'pages/noAccess.page';
import { AuthContext } from 'contexts/auth.context';
import ConfirmDialog from 'components/dialog/dialog.component';
import { ThemeContext, LightTheme, DarkTheme } from 'contexts/theme.context';
import TeamsPage from 'pages/teams/teams.page';
import Footer from 'components/navbar/footer.component';
import NotFoundPage from 'pages/notFound.page';

function App() {
  const { user } = useContext(AuthContext);
  const { colorMode } = useContext(ThemeContext);

  function generateRoutes(structure) {
    return Object.keys(structure).map((key) => {
      const item = structure[key];
      if ('link' in item && !item.custom) {
        return (
          <Route
            key={key}
            path={item.link}
            element={
              (item.admin && user?.is_admin) || !item.admin ? (
                <BasePage key={key} title={item.title} />
              ) : (
                <NoAccessPage />
              )
            }
          />
        );
      } else if ('dropdown' in item) {
        return generateRoutes(item.dropdown);
      }
    });
  }

  return (
    <ThemeProvider theme={colorMode === 'light' ? LightTheme : DarkTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <Router>
          <Navbar />
          <Container maxWidth="xl" sx={{ mt: '10px', mb: '1%', pt: '1%', flex: 1 }}>
            <Box>
              <Routes>
                <Route key="/" path="/" element={<Home />} />
                <Route key="/admin-login" path="/admin-login" element={<AdminLoginPage />} />
                <Route key="/news" path="/news" element={<NewsPage />} />
                <Route key="/news/:id" path="/news/:id" element={<NewsPage />} />
                <Route
                  key="/news/verwalten"
                  path="/news/verwalten"
                  element={user?.is_admin ? <ManagePostsPage /> : <NoAccessPage />}
                />
                <Route
                  key="/news/verwalten/:id?"
                  path="/news/verwalten/:id?"
                  element={user?.is_admin ? <ManagePostsPage /> : <NoAccessPage />}
                />
                <Route key="/teams/:team" path="/teams/:team" element={<TeamsPage />} />
                {/* Footer pages */}
                <Route
                  key="/datenschutzerklaerung"
                  path="/datenschutzerklaerung"
                  element={<BasePage key="/datenschutzerklaerung" title="Datenschutzerklärung" />}
                />
                <Route
                  key="/impressum"
                  path="/impressum"
                  element={<BasePage key="/impressum" title="Impressum" />}
                />
                {generateRoutes(navStructure)}
                <Route key="*" path="*" element={<NotFoundPage />} />
              </Routes>
            </Box>
          </Container>
          <Footer />
        </Router>
        <ConfirmDialog />
      </Box>
    </ThemeProvider>
  );
}

export default App;
