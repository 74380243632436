import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DialogContext } from 'contexts/dialog.context';
import { useContext } from 'react';

function ConfirmDialog() {
  const { dialogProps, setDialogProps } = useContext(DialogContext);

  function handleClick(option: number) {
    switch (option) {
      // Cancel
      case 1:
        break;
      // Confirm
      case 2:
        dialogProps.onConfirm();
        break;
      // Optional third option
      case 3:
        if (dialogProps.onThirdOption) {
          dialogProps.onThirdOption();
        }
        break;
    }
    setDialogProps({ ...dialogProps, open: false });
  }

  return (
    <Box>
      <Dialog open={dialogProps.open}>
        <DialogTitle sx={{ alignSelf: 'center' }}>{dialogProps.message}</DialogTitle>
        <DialogContent sx={{ alignSelf: 'center' }}>{dialogProps.content}</DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between', marginLeft: '5%', marginRight: '5%' }}>
          <Button
            onClick={() => handleClick(1)}
            variant="contained"
            sx={{
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
              },
              cursor: 'pointer',
            }}>
            Abbrechen
          </Button>
          {dialogProps.thirdOptionText && dialogProps.onThirdOption && (
            <Button
              onClick={() => handleClick(3)}
              variant="contained"
              sx={{
                bgcolor: 'warning.main',
                '&:hover': {
                  bgcolor: 'warning.dark',
                },
                cursor: 'pointer',
              }}>
              {dialogProps.thirdOptionText}
            </Button>
          )}
          <Button
            onClick={() => handleClick(2)}
            variant="contained"
            sx={{
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
              cursor: 'pointer',
            }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ConfirmDialog;
