import { IconButton, IconButtonProps } from '@mui/material';

function CustomIconButton(props: IconButtonProps) {
  return (
    <IconButton
      {...props}
      sx={{
        color: 'white',
        ...props.sx,
      }}
    />
  );
}

export default CustomIconButton;
