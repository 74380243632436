import { TextField, TextFieldProps } from '@mui/material';

function CustomTextField(props: TextFieldProps) {
  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
        },
      }}
      {...props}
    />
  );
}

export default CustomTextField;
