import { Box, Button, Divider, Fade, Paper, Popper, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { NavDropdown, NavItem, navStructure } from '../../config/navbarItems';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import SocialIconsList from './socialIconsList.component';
import FunctionIconsList from './functionIconsList.component';
import { AuthContext } from 'contexts/auth.context';

function NavbarDesktop() {
  const { user } = useContext(AuthContext);

  function renderNavDropdown(key: string, value: NavDropdown, layer: number = 0) {
    const [anchorElDropdown, setAnchorElDropdown] = useState<null | HTMLElement>(null);
    const [openDropdown, setOpenDropdown] = useState(false);

    return (
      (!value.admin || user?.is_admin) && (
        <Box onMouseLeave={() => setOpenDropdown(false)}>
          <Box>
            <Button
              id={key}
              sx={{
                my: layer === 0 ? 2 : 0,
                textTransform: 'none',
                minWidth: '100%',
              }}
              onMouseEnter={(event) => {
                setOpenDropdown(true);
                setAnchorElDropdown(event.currentTarget.parentElement);
              }}
              aria-haspopup="true">
              <Typography
                textAlign="center"
                fontWeight={layer == 0 ? 'bold' : 'normal'}
                sx={{ color: 'white', display: 'flex' }}>
                {value.title}
                {layer == 0 ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </Typography>
            </Button>
          </Box>
          <Popper
            id={key}
            open={openDropdown}
            anchorEl={anchorElDropdown}
            placement={layer == 0 ? 'bottom-start' : 'right-start'}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  sx={{
                    ml: layer === 0 ? 0 : '1%',
                    width: '100%',
                    backgroundColor: grey[800],
                  }}>
                  {Object.entries(value.dropdown).map(([key, value], index) => {
                    return (
                      (!value.admin || user?.is_admin) && (
                        <Box key={key}>
                          {index != 0 && (
                            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', height: '1px' }} />
                          )}
                          {'dropdown' in value ? (
                            renderNavDropdown(key, value, layer + 1)
                          ) : (
                            <Button
                              onClick={() => setOpenDropdown(false)}
                              sx={{
                                textTransform: 'none',
                                width: '100%',
                              }}>
                              <Link to={value.link} className="navbar-link">
                                <Typography
                                  textAlign="center"
                                  sx={{ color: 'white', display: 'flex' }}>
                                  {value.title}
                                </Typography>
                              </Link>
                            </Button>
                          )}
                        </Box>
                      )
                    );
                  })}
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      )
    );
  }

  function renderNavItem(key: string, value: NavItem) {
    return (
      (!value.admin || user?.is_admin) && (
        <Link key={key} to={value.link} className="navbar-link">
          <Button sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
            <Typography textAlign="center" fontWeight="bold" sx={{ color: 'white' }}>
              {value.title}
            </Typography>
          </Button>
        </Link>
      )
    );
  }

  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
      }}>
      {/* Social Icons */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pb: '10px',
        }}>
        <SocialIconsList />
        <FunctionIconsList />
      </Box>

      <Divider sx={{ bgcolor: 'white' }} />

      <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
        {Object.entries(navStructure).map(([key, value]) =>
          'dropdown' in value ? (
            <Box key={key}>{renderNavDropdown(key, value)}</Box>
          ) : (
            <Box key={key}>{renderNavItem(key, value)}</Box>
          ),
        )}
      </Box>
    </Box>
  );
}

export default NavbarDesktop;
